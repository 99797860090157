import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin, notification, Typography, Tooltip, Tabs } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { fetchTransformList, fetchZPALGLOBALList } from '../../redux/AssetUtilization/action.js';
import moment from 'moment';
import { GetRefreshInfo } from '../../redux/AssetUtilization/saga.js';
import { cellFormatter } from '../FinanceTabs/Uitiles.js';
import { restoreGridLayout, saveGridLayout } from './Uitiles.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const TransformData = (props) => {

    const { onFetchRawCrcPmList, rawCrcPmList, isRawCrcPmListLoading, } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    // const [columnDefs1, setColumnDefs1] = useState([


    //     { field: 'CoCd', headerName: 'Cocd', editable: true, filter: 'agMultiColumnFilter' },
    //     { field: 'Material', headerName: 'Material', editable: true, filter: 'agMultiColumnFilter' },
    //     { field: 'Serial_number', headerName: 'Serial_number', editable: true, filter: 'agMultiColumnFilter',  },
    //     { field: 'Material_Description', headerName: 'Material_Description', editable: true, filter: 'agMultiColumnFilter' },
    //     { field: 'Asset', headerName: 'Asset', editable: true, filter: 'agMultiColumnFilter'},
    //     { field: 'PlanRfrom', headerName: 'PlanRfrom', editable: true, filter: 'agMultiColumnFilter' },
    //     { field: 'PlanR_to', headerName: 'PlanR_to', editable: true, filter: 'agMultiColumnFilter'},
    //     { field: 'Customer', headerName: 'Customer ',   filter: 'agMultiColumnFilter', tooltipField: 'gLAcctLongText'},
    //     { field: 'Document', headerName: 'DocumentNo', editable: true, filter: 'agMultiColumnFilter'},
    //     { field: 'Item', headerName: 'Item', editable: true, filter: 'agMultiColumnFilter'},
    //     { field: 'Indicator', headerName: 'Indicator', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'WBS_Element', headerName: 'WBS_Element', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'RentGrp', headerName: 'RentGrp', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'Rental_Group', headerName: 'Rental_Group', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'Acqvalue', headerName: 'Acqvalue', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'Plnt', headerName: 'Plnt', editable: true, filter: 'agMultiColumnFilter', },
    //     { field: 'Crcy', headerName: 'Crcy',  filter: 'agMultiColumnFilter', cellStyle: { textAlign: 'right' }},

    // ]);

    // const [refreshInfo, setRefreshInfo] = useState(null);
    // useEffect(() => {

    //     fetchInfo('ZPAL_GLOBAL');
    // }, []);

    // const fetchInfo = async (key) => {
    //     try {
    //         const info = await GetRefreshInfo(key);
    //         if (info.Date_Time) {
    //             setRefreshInfo(info.Date_Time)
    //         }
    //     } catch (error) {
    //         console.error("Error fetching info:", error);
    //     }
    // };


    const generateColumns = async (columnList) => {
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'ID' && key !== 'totalCount') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly

                valueFormatter: (params) => {
                    // Check if the column is a date column
                    const isDateColumn = ['PlanRfrom', 'PlanR_to', 'Capdate', 'EquipCrDt', 'RetDate', 'StrtDate'].includes(key);

                    if (isDateColumn) {
                        // Validate and format the date
                        const date = moment(params.value); // Parse date with moment.js
                        return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                    }


                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: { textAlign: 'left' }, // Cell alignment
                editable: true, // Enable editing for cells
                filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));

        // Debugging output for generated columns
        console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };


    useEffect(() => {
        if (rawCrcPmList.length == 0) {
            onFetchRawCrcPmList({ tableName: 'Utilization', pageNumber: pageNumber, pageSize: pageSize });

        }
    }, [])


    useEffect(() => {
        const fetchData = async () => {
            if (rawCrcPmList.length > 0) {
                if (columnDefs.length === 0) {
                    await generateColumns(rawCrcPmList[0]);  // Wait until columns are generated
                }
                setRowData(rawCrcPmList);  // After columns are set, update rowData
                setLayoutLoad(true)
                setLoadingProgress(100);  // Finish loading progress
            }
        };

        fetchData();  // Call the async function inside useEffect
    }, [rawCrcPmList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    useEffect(() => {
        if (layoutSave && gridRef) {
            (async () => {
                let layouts = {}
                await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
            })();

        }


    }, [layoutSave])

    useEffect(() => {
        if (layoutLoad && gridRef) {
            (async () => {
                await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
                setLayoutLoad(false)
            })();


        }
    }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        };
    }, []);
    const currentTab = 'Utilization';


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [currentTab]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [currentTab]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawCrcPmListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawCrcPmListLoading]);

    //

    useEffect(() => {
        if (isRawCrcPmListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawCrcPmListLoading]);

    useEffect(() => {
        if (isRawCrcPmListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawCrcPmListLoading]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left'
            }
        ]
    });

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };





    return (

        <div className="d-flex flex-column h-100">

            <div style={{ height: 'calc(100vh - 135px)', overflow: 'auto', position: 'relative' }}>
                <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        onRowDragEnd={onRowDragEnd}
                        defaultColDef={defaultColDef}
                        rowSelection={rowSelection}
                        onGridReady={onGridReady}
                        sideBar={true}
                        pivotMode={isPivotMode}
                        //
                        pivotPanelShow={"always"}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onSelectionChanged={onSelectionChanged}
                        // enableFilter={true}
                        statusBar={statusBar}
                        rowHeight={40}
                        headerHeight={50}
                        defaultExcelExportParams={defaultExcelExportParams}
                        defaultCsvExportParams={defaultCsvExportParams}

                    />
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = ({ assetUtilizationReducer }) => {
    const isRawCrcPmListLoading = get(assetUtilizationReducer, 'isTransformListLoading', false);
    const rawCrcPmList = get(assetUtilizationReducer, 'rawTransformList', []);

    return {
        isRawCrcPmListLoading,
        rawCrcPmList,
    }
}

const mapDispatchToProps = {
    onFetchRawCrcPmList: fetchTransformList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransformData);