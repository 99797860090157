/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip, Typography, Select,Button } from 'antd';
import { useHistory } from 'react-router-dom';
import ZPal_Global from './ZPal_Global';
import TransformData from './TransformData';
import { connect } from 'react-redux';
const { TabPane } = Tabs;
const { Title,Text } = Typography;
const { Option } = Select;

const UtilizationTaps = (props) => {

    const {
        setShowRawData,
        refreshInfo

    } = props

    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('1');
    const [year, setYear] = useState([])
    const [selectedYear, setSelectedYear] = useState(null);
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);


    const onChange = (key) => {
        setCurrentSection(key);
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex mb-2 tab-content-title" style={{ justifyContent: 'space-between' }}>
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={(e) => {
                        setShowRawData(false)
                        }} />
                    </Tooltip>
                    <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        RAW Data
                    </Title>
                </div>
                <div className="new-calibration">
                <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Refresh Date: {refreshInfo}</Text>
                   
                </div>
               
            </div>
            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="ZPAL GLOBAL" key="ZPAL_GLOBAL">
                        <ZPal_Global>

                        </ZPal_Global>
                       
                    </TabPane>
                    <TabPane tab="Transform Data" key="Utilization">
                       <TransformData>

                       </TransformData>
                       </TabPane>

                </Tabs>
            </div>
        </div>
    );
};


const mapStateToProps = ({ }) => {

    return {

    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(UtilizationTaps);