import { combineReducers } from 'redux';
import { SIGNOUT } from './types';
import auth from './reducer/authReducer';
import licenseMangerReducer from './LicenseManager/reducer'
import calibrationReducer from '../redux/calibration/reducer'
import sparepartsreducer from '../redux/Spareparts/reducer'
import RecordListReducer from '../redux/Trackrecord/reducer'
import PricingListReducer from '../redux/Pricing/reducer'
import projectMarginReducer from './projectMargin/reducer';
import assetUtilizationReducer from './AssetUtilization/reducer';

const appReducer = combineReducers({
  auth,licenseMangerReducer,calibrationReducer,sparepartsreducer,RecordListReducer,PricingListReducer,projectMarginReducer,assetUtilizationReducer
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGNOUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;
